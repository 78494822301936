body { 
  margin: 0;
}

canvas {
  width: 100%;
  position: relative;
  border: solid 50px black;
  transition: filter 1.0s ease;
  top: 0px;
}

* {
  box-sizing: border-box;
}

.textbox-popup {
  pointer-events: none;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-family: arial;
  font-size: calc(10px + 0.3vw);
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.7);
  padding: 15px;
  background: rgba(5, 5, 5, 0.6);
  transition: all 1s ease;
  touch-action: none;
  opacity: 0
}

.clicked {
  opacity: 1;
  pointer-events: none !important;
  touch-action: none;
  top: 50%;
}

.pickableCanvas {
  position: relative;
  height: 100%;
  line-height: 0px; /*fix to make canvas same height as div, without this it expands based on line-height*/
  z-index: 1;
}

.pickableCanvas:before {
  content: " ";
  border: solid 0.5px #666666;
  position: absolute;
  z-index: 1;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
}

.pickableCanvas:after {
  content: " ";
  border: solid 0.5px #666666;
  position: absolute;
  z-index: 1;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}

.pickableCanvas .cross-hair {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  z-index: 1;
  border: 2px solid black;
  opacity: 0.7;
}

.App-header .right-arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 75px;
  right: 10%;
  color: #bbbbbb;
  opacity: 0.5;
  z-index: 2;
}

.App-header .right-arrow svg {
  width: 50px;
  height: 50px;
}

.App-header .left-arrow {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 75px;
  left: 10%;
  color: #bbbbbb;
  opacity: 0.5;
  z-index: 2;
}

.App-header .left-arrow svg {
  width: 50px;
  height: 50px;
}

.page-container .github-icon {
  pointer-events: auto;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 75px;
  left: 75px;
  color: #bbbbbb !important;
  opacity: 0.5;
  z-index: 2;
}

.github-icon svg {
  width: 50px;
  height: 50px;
}

.page-container .linked-icon {
  pointer-events: auto;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 75px;
  right: 75px;
  color: #bbbbbb !important;
  opacity: 0.5;
  z-index: 2;
}

.linked-icon svg {
  width: 50px;
  height: 50px;
}

.github-icon a:visited {
  color: #bbbbbb;
}

.linked-icon a:visited {
  color: #bbbbbb;
}

.github-icon a {
  color: #bbbbbb;
}

.linked-icon a {
  color: #bbbbbb;
}


.github-icon {
  transition: all 0.3s ease;
}

.linked-icon {
  transition: all 0.3s ease;
}

.github-icon:hover {
  color: #bbbbbb;
  opacity: 1;
}

.linked-icon:hover {
  color: #bbbbbb;
  opacity: 1;
}

.page-container {
  width: 100%;
  max-width: 100%;
}

.page-container div {
  width: 100%;
  max-width: 100%;
}

.page-container .control-toggle-container {
  width: 230px;
  padding: 10px;
  position: fixed;
  z-index: 2;
  bottom: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0.2;
  font-size: 12px;
  border: 1px solid #EEEEEE;
  cursor: pointer;
  transition: all 0.3s ease;
}

.page-container .control-toggle-container:hover {
  opacity: 0.5;
}

.page-container .control-toggle-container svg {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 800px) {
  .page-container .github-icon {
    top: 60px;
    left: 15px;
  }

  .page-container .linked-icon {
    top: 60px;
    right: 15px;
  }

  canvas {
    border: solid 5px black;
    border-top-width: 50px;
    border-bottom-width: 50px;
  }

  .pickableCanvas:before {
    border: solid 0.5px #666666;
    left: 5px;
    right: 5px;
  }
  
  .pickableCanvas:after {
    border: solid 0.5px #666666;
  }
}

@media screen and (max-height: 500px) {
  canvas {
    border: solid 5px black;
  }

  .pickableCanvas:before {
    border: solid 0.5px #666666;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
  }
}