.contact-page-container {
    max-width: 80%;
    width: 1280px;
    transition: all 1.0s ease;
    overflow-y: scroll;
    position: absolute;
    top: 45px;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgba(36, 36, 36, 0.9);
    -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none;  /* Firefox */
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 45px 2px black;
    opacity: 0;
  }
  
  .contact-page-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  
  .show {
    max-width: 80%;
    width: 1280px;
    top: 75px;
    bottom: 75px;
    transition: all 1.0s ease;
    opacity: 1;
  }
  
  .contact-header {
    position: relative;
    width: 100%;
    height: 320px;
    margin-bottom: -50px;
    transition: all 1s ease;
  }
  
  .contact-header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: -25px;
    object-position: 0% 30%;
  }
  
  .contact-header::after {
    content: " ";
    width: 100%;
    height: 320px;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: -25px;
    /* background-image: linear-gradient(transparent, transparent, transparent, rgba(36, 36, 36, 1.0)); */
    z-index: 1;
    border-bottom: 5px solid #666666;
  }
  
  .close-me {
    position: sticky;
    top: 10px;
    margin-left: auto;
    margin-right: 12px;
    color: #ff3300;
    width: 25px;
    height: 25px;
    z-index: 2;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .close-me:hover {
    opacity: 1.0;
  }
  
  .what-i-do {
    padding-top: 45px;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    padding-bottom: 75px;
  }
  
  .what-i-do-header {
    max-width: 90%;
    width: 1080px;
    text-align: left;
    color: #BBBBBB;
    font-size: 60px;
    font-weight: 400;
    padding-top: 75px;
    margin: 0 auto;
    margin-bottom: 20px;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .what-i-do-header-2 {
    max-width: 90%;
    width: 1080px;
    text-align: left;
    color: #BBBBBB;
    font-weight: 400;
    padding-top: 25px;
    margin: 5px auto;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .what-i-do-text {
    max-width: 90%;
    width: 1080px;;
    text-align: left;
    color: #BBBBBB;
    margin: 0 auto;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .show .what-i-do-text,
  .show .what-i-do-header,
  .show .what-i-do-header-2,
  .show .contact-header,
  .show .contact-form {
    opacity: 1;
  }

  /************************************** CONTACT FORM *************************************/

  textarea {
    transform: translateZ(0);
  }

  .contact-form {
    max-width: 90%;
    width: 1080px;
    margin: 0 auto;
    transition: all 1s ease;
    margin-top: 25px;
  }

  .contact-form input,
  .contact-form textarea,
  .form-message {
    width: 60%;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    min-height: 40px;
    font-size: 15px;
    box-shadow: none;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(36, 36, 36, 0.0) !important;
    border: 1px solid #BBBBBB;
    color: #FFFFFF;
    transition: all 1s ease;
  }

  .form-message {
    padding: 0px;
    text-align: left;
    border: 0px;
    color: greenyellow;
    min-height: unset;
  }

  .contact-form textarea {
    min-height: 160px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .contact-form .submit-input {
    width: 120px;
  }

  .contact-form .submit-input:hover {
    width: 120px;
    background-color: rgba(86, 86, 86, 1.0) !important;
    cursor: pointer;
  }
  
  .break {
    min-height: 0px !important;
    height: 0px;
  }


  /************************************** CONTACT FORM *************************************/


  @media screen and (max-width: 768px) {

    .contact-page-container {
      max-width: 100%;
    }

    .show {
      height: 100%;
      bottom: 0px;
      width: 100%;
      max-width: 100%;
      top: 0px;
    }

    .contact-header-image {
      object-position: 90% 30%;
    }

    .contact-form {
      max-width: 100%;
      width: 1080px;
      margin: 0 auto;
      transition: all 1s ease;
      margin-top: 25px;
    }

    .contact-form input,
    .contact-form textarea,
    .form-message {
      width: 100%;
    }

    .contact-content .what-i-do-header,
    .contact-content .what-i-do-header-2,
    .contact-content .what-i-do-text {
      max-width: 100%;
      width: 1080px;
    }
  
    .contact-content .what-i-do-header {
      font-size: 30px;
    }
  
    .contact-content .what-i-do-header-2 {
      font-size: 20px;
    }
  
    .contact-header {
      height: 170px;
    }
  
    .contact-header::after {
      height: 170px;
    }
  
    .what-i-do {
      padding-top: 10px;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 35px;
    }
  }

  @media screen and (max-height: 500px) {
    .contact-page-container {
      max-width: 100%;
    }

    .show {
      height: 100%;
      bottom: 0px;
      width: 100%;
      max-width: 100%;
    }
  }