html {
  background-color: black;
  overflow: hidden;
  width: 100%;
}

.App {
  text-align: center;
  position: relative;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* transition: all 1.5s ease; */
  opacity: 1;
  overflow: hidden;
  position: relative;
  z-index: 0;
  bottom: 0px;
}

.App-link {
  color: #61dafb;
}

.header-hidden {
  height: 0px;
  opacity: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
