.portfolio-page-container {
    max-width: 80%;
    width: 1280px;
    transition: all 1.0s ease;
    overflow-y: scroll;
    position: absolute;
    top: 45px;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, 0%);
    background-color: rgba(36, 36, 36, 0.9);
    -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none;  /* Firefox */
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 45px 2px black;
    opacity: 0;
  }
  
  .portfolio-page-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
  
  .show {
    max-width: 80%;
    width: 1280px;
    top: 75px;
    bottom: 75px;
    transition: all 1.0s ease;
    opacity: 1;
  }
  
  .portfolio-header {
    position: relative;
    width: 100%;
    height: 320px;
    margin-bottom: -50px;
    transition: all 1s ease;
  }
  
  .portfolio-header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: -25px;
    object-position: center 55%;
  }
  
  .portfolio-header::after {
    content: " ";
    width: 100%;
    height: 320px;
    position: absolute;
    top: 0px;
    left: 0px;
    margin-top: -25px;
    /* background-image: linear-gradient(transparent, transparent, transparent, rgba(36, 36, 36, 1.0)); */
    z-index: 1;
    border-bottom: 5px solid #666666;
  }
  
  .what-i-do {
    padding-top: 45px;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    padding-bottom: 75px;
  }
  
  .what-i-do-header {
    max-width: 90%;
    width: 1080px;
    text-align: left;
    color: #BBBBBB;
    font-size: 60px;
    font-weight: 400;
    padding-top: 75px;
    margin: 0 auto;
    margin-bottom: 20px;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .what-i-do-header-2 {
    max-width: 90%;
    width: 1080px;
    text-align: left;
    color: #BBBBBB;
    font-weight: 400;
    padding-top: 25px;
    margin: 5px auto;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .what-i-do-text {
    max-width: 90%;
    width: 1080px;
    text-align: left;
    color: #BBBBBB;
    margin: 0 auto;
    transition: all 1s ease !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .show .what-i-do-text,
  .show .what-i-do-header,
  .show .what-i-do-header-2,
  .show .portfolio-grid-body,
  .show .portfolio-header {
    opacity: 1;
  }

  /******************************** PORTFOLIO SPECIFIC ****************************************/

  .portfolio-grid-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1%;
    row-gap: 1%;
    max-width: 90%;
    width: 1080px;
    margin: 0 auto;
    margin-top: 50px;
    opacity: 0;
    transition: all 1s ease;
  }

  .portfolio-grid-item {
    height: 200px;
    width: 100%;
    border: 1px solid #666666;
    overflow: hidden;
    position: relative;
  }

  .portfolio-grid-item:hover .portfolio-image {
    transform: scale(1.05);
    filter: brightness(0.3);
  }

  .portfolio-grid-item:hover .portfolio-item-details {
    opacity: 1;
    pointer-events: auto;
  }


  .portfolio-image {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    transition: all 0.7s ease;
    transform: translateZ(0);
  }
  
  .portfolio-item-details {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 1s ease;
    pointer-events: none;
  }

  .portfolio-item-details a {
    text-shadow: 0px 0px 5px black;
  }

  .code-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #BBBBBB;
    opacity: 0.5;
    transition: all 1s ease;
  }

  .code-icon:hover {
    opacity: 1;
    transition: all 1s ease;
  }

  .code-icon svg {
    pointer-events: none;
  }

  .item-link {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-decoration: none;
    text-shadow: 0px 0px 2px black;
    color: #FFFFFF;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  /******************************** PORTFOLIO SPECIFIC ****************************************/

  @media screen and (max-width: 950px) {
    .item-link {
      font-size: 18px;
    }
  }


  @media screen and (max-width: 768px) {
    .portfolio-page-container {
      max-width: 100%;
    }

    .portfolio-grid-body {
      display: grid;
      grid-template-columns: 1fr;
    }

    .portfolio-content .what-i-do-header,
    .portfolio-content .what-i-do-header-2,
    .portfolio-content .what-i-do-text {
      max-width: 100%;
      width: 1080px;
    }
  
    .portfolio-content .what-i-do-header {
      font-size: 30px;
    }
  
    .portfolio-content .what-i-do-header-2 {
      font-size: 20px;
    }
  
    .portfolio-header {
      height: 170px;
    }
  
    .portfolio-header::after {
      height: 170px;
    }
  
    .what-i-do {
      padding-top: 10px;
      padding-left: 25px;
      padding-right: 25px;
      width: 100%;
      padding-bottom: 35px;
    }

    .portfolio-grid-body {
      max-width: 100%;
    }
  }
  
  @media screen and (max-height: 500px) {
    .portfolio-page-container {
      max-width: 100%;
    }
  }