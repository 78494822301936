.about-page-container {
  max-width: 80%;
  width: 1280px;
  transition: all 1.0s ease;
  overflow-y: scroll;
  position: absolute;
  top: 45px;
  bottom: 45px;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(36, 36, 36, 0.9);
  -ms-overflow-style: none;  /* IE 10+ */
  scrollbar-width: none;  /* Firefox */
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 0px 45px 2px black;
  opacity: 0;
}

.about-page-container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.show {
  max-width: 80%;
  width: 1280px;
  top: 75px;
  bottom: 75px;
  transition: all 1.0s ease;
  opacity: 1;
}

.about-header {
  position: relative;
  width: 100%;
  height: 320px;
  margin-bottom: -50px;
  transition: all 1s ease;
}

.about-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: -25px;
  object-position: center center;
}

.about-header::after {
  content: " ";
  width: 100%;
  height: 320px;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-top: -25px;
  /* background-image: linear-gradient(transparent, transparent, transparent, rgba(36, 36, 36, 1.0)); */
  z-index: 1;
  border-bottom: 5px solid #666666;
}

.what-i-do {
  padding-top: 45px;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  padding-bottom: 75px;
}

.what-i-do-header {
  max-width: 90%;
  width: 1080px;
  text-align: left;
  color: #BBBBBB;
  font-size: 60px;
  font-weight: 400;
  padding-top: 75px;
  margin: 0 auto;
  margin-bottom: 20px;
  transition: all 1s ease !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.what-i-do-header-2 {
  max-width: 90%;
  width: 1080px;
  text-align: left;
  color: #BBBBBB;
  font-weight: 400;
  padding-top: 25px;
  margin: 5px auto;
  transition: all 1s ease !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.what-i-do-text {
  max-width: 90%;
  width: 1080px;
  text-align: left;
  color: #BBBBBB;
  margin: 0 auto;
  transition: all 1s ease !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.show .what-i-do-text,
.show .what-i-do-header,
.show .what-i-do-header-2,
.show .about-header {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .about-page-container {
    max-width: 100%;
  }
  
  .about-content .what-i-do-header,
  .about-content .what-i-do-header-2,
  .about-content .what-i-do-text {
    max-width: 100%;
    width: 1080px;
  }

  .about-content .what-i-do-header {
    font-size: 30px;
  }

  .about-content .what-i-do-header-2 {
    font-size: 20px;
  }

  .about-header {
    height: 170px;
  }

  .about-header::after {
    height: 170px;
  }

  .what-i-do {
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    padding-bottom: 35px;
  }
}

@media screen and (max-height: 500px) {
  .about-page-container {
    max-width: 100%;
  }
}